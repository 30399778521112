@import "./breakpoints";
@import "./colours";

@font-face {
  font-family: blackpast;
  src: url("../assets/fonts/blackpast.ttf");
}

@font-face {
  font-family: poppins;
  src: url("../assets/fonts/poppins/Poppins-Light.woff");
}

@font-face {
  font-family: poppinsBold;
  src: url("../assets/fonts/poppins/Poppins-Regular.woff");
}

@mixin logo {
  font-family: blackpast;
  font-size: 36px;
  line-height: 64px;
  color: white;

  @include desktop {
    font-size: 36px;
    line-height: 60px;
  }
}

@mixin hero {
  font-family: poppinsBold;
  font-size: 11vw;
  line-height: 13vw;
  color: white;

  @media screen and (orientation: landscape) {
    font-size: 5vw;
  }

  @include tablet {
    font-size: 9vw;
    line-height: 10vw;
  }

  @include desktop {
    font-size: 72px;
    line-height: 104px;
  }
}

@mixin hero-small {
  font-family: poppins;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 12%;
  color: white;
}

@mixin page-header {
  font-family: poppinsBold;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 2px;
  color: white;
  @include desktop {
    letter-spacing: normal;
    font-size: 32px;
    line-height: 48px;
  }
}

@mixin section-header {
  font-family: poppinsBold;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 12%;
  color: white;
}

@mixin home-section-header {
  font-family: poppinsBold;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 12%;
  color: white;
}

@mixin bold {
  font-family: poppinsBold;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 12%;
  color: white;
}

@mixin body {
  font-family: poppins;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 12%;
  color: white;
}

@mixin body-bold {
  font-family: poppinsBold;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 12%;
  color: white;
}

@mixin nav {
  font-family: poppinsBold;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 12%;
  color: white;
}

@mixin button {
  font-family: poppinsBold;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 12%;
  color: white;

  @media screen and (orientation: landscape) {
    font-size: 18px;
  }
}
