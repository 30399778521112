@import "../../styles/fonts";
@import "../../styles/breakpoints";

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: fit-content;
  height: 100vh;
  width: 100vw;

  background: url("../../assets/images/hero.jpg") 60% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (orientation: landscape) {
    min-height: 200vh;
    justify-content: space-evenly;
  }

  @include desktop {
    justify-content: space-evenly;

    min-height: 100vh;
    height: fit-content;
    max-height: none;
    width: 100%;

    background: url("../../assets/images/hero-desktop.jpg") 20% 0%;
    background-position: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    height: 95%;
    width: 100%;

    padding-left: 4%;
    padding-right: 4%;

    @include desktop {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }

  &__text {
    @include hero;
    padding: 6%;

    max-width: 100%;

    letter-spacing: 2.4px;
    font-weight: 400;
    line-height: 118%;

    background-color: rgba(68, 76, 108, 0.15);
    backdrop-filter: blur(10px);
    width: fit-content;

    @include desktop {
      padding: 4%;
      line-height: normal;
    }

    &--mobile {
      display: flex;

      @include desktop {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include desktop {
        display: flex;
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;

    &-button {
      @include button;
      width: 180px;
      height: 48px;

      background-color: #342593;
      color: white;
      border-radius: 0;
      text-align: center;

      border: solid 1px #342593;
      align-self: center;
      transition: 0.3s;

      @include desktop {
        align-self: flex-start;
        margin-left: 2%;
        width: 170px;
      }

      &:hover {
        background-color: #21166d;
      }
    }
  }

  &__down {
    display: flex;
    flex-direction: row;
    justify-content: center;

    height: 5%;

    &-button {
      width: 40px;
      height: 40px;
      align-self: center;

      @include desktop {
      }
    }
  }
}
