@import "./breakpoints";

$padding-side-mobile: 24px;
$padding-before-headline: 40px;
$padding-between-sections: 24px;
$component-top: 24px;
$component-bottom: 24px;
$padding-page-top: 56px;
$padding-page-end: 80px;

$padding-side--desktop: 4%;
$padding-before-headline--desktop: 10vh;
$padding-between-sections--desktop: 24px;
$component-top--desktop: 3%;
$component-bottom--desktop: 3%;
$padding-page-top--desktop: 60px;
$padding-page-end--desktop: 12vh;

@mixin padding-side {
  padding-left: $padding-side-mobile;
  padding-right: $padding-side-mobile;

  @include tablet {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include desktop {
    padding-left: $padding-side--desktop;
    padding-right: $padding-side--desktop;
  }
}

@mixin padding-before-headline {
  padding-top: $padding-before-headline;

  @include desktop {
    padding-top: $padding-before-headline--desktop;
  }
}

@mixin padding-page-top {
  padding-top: $padding-page-top;
}

@mixin padding-page-end {
  padding-bottom: $padding-page-end;

  @include desktop {
    padding-bottom: $padding-page-end--desktop;
  }
}

@mixin padding-component-top-bottom {
  padding-top: $component-top;
  padding-bottom: $component-bottom;

  @include desktop {
    padding-top: $component-top--desktop;
    padding-bottom: $component-bottom--desktop;
  }
}
