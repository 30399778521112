@import "../../styles/breakpoints";
@import "../../styles/padding";

.contact {
  @include padding-page-top;
  padding-top: 12vh;

  display: flex;
  flex-direction: column;
  align-content: center;

  min-height: 100vh;
  width: 100vw;
  max-width: 2000px;

  background-color: #131314;

  &__main {
    width: 100%;
    min-height: 90vh;
    height: fit-content;

    @include desktop {
      display: flex;
      flex-direction: column;
    }
  }

  &__container {
    @include padding-component-top-bottom;
    @include padding-side;
    display: flex;
    flex-direction: column;
    gap: 5%;
    width: 100%;

    @include desktop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-self: center;

      // padding-top: 2vh;
    }
  }

  &__text {
  }

  &__email {
    &-title {
      margin: 0;
      align-self: flex-end;
    }

    &-container {
      align-self: flex-end;
      margin-top: 0;

      @include desktop {
        align-self: center;
      }
    }
  }
  &__mailto {
    &-text {
      padding: 0;
      margin: 0;
      text-decoration: underline;
      &:hover {
        color: #72e1d1;
      }
    }
  }

  &__divider {
    display: none;

    @include desktop {
      display: flex;
      align-self: center;
      width: 90%;
      height: 2px;
      background-color: #72e1d1;
    }
  }

  &__left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0;

    @include desktop {
      width: 40%;
    }
  }

  &__right {
    width: 100%;

    @include desktop {
      width: 60%;
      padding-top: 4vh;
    }
  }
}
