@import "./styles/colours";
@import "./styles/resets";
@import "./styles/breakpoints";
a,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #131314;
}

.logo {
  width: 160px;
  position: absolute;
  top: 40px;
  left: 32px;

  @include desktop {
    width: 10%;
    left: 4%;
    top: 40px;
  }
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  // max-width: 2000px;
  // margin: auto;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
