@import "../../styles/breakpoints";
@import "../../styles/padding";

.footer {
  @include padding-side;

  display: flex;
  flex-direction: column;

  height: fit-content;
  width: 100%;

  padding-top: 40px;
  padding-bottom: 32px;

  background-color: black;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    padding-top: 64px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__logo {
    object-fit: scale-down;
    width: 30%;

    padding-bottom: 24px;
    @include desktop {
      width: 10%;
    }
  }

  &__heading {
    line-height: 30px;
  }

  &__text {
    font-size: smaller;
    line-height: 30px;
    margin-top: 3px;
  }

  &__link {
    font-size: smaller;
    line-height: 30px;
    margin-top: 3px;
    color: white;
    text-decoration: underline;
    &:hover {
      color: #72e1d1;
      text-decoration: underline;
    }
  }

  &__copyright {
    font-size: 12px;
    line-height: 12px;

    @include desktop {
      align-self: flex-end;
    }
  }
}
