@import "../../../styles/breakpoints";

.hamburger {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  position: fixed;
  top: 40px;
  left: 85%;
  z-index: 10000;

  @include desktop {
    display: none;
  }
}

.burger {
  width: 32px;
  height: 2px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
  transform-origin: 1px;
  transition: all 0.3s linear;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-backdrop-filter: blur(15px);
}
