@import "../../styles/breakpoints";
@import "../../styles/fonts";

.navDesktop {
  padding-right: 2%;
  display: none;

  @include desktop {
    display: flex;
    position: fixed;
    top: 10px;
    z-index: 10000;

    flex-direction: row;

    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    gap: 16px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    // width: 100vw;
    justify-content: flex-end;
    gap: 32px;
    width: fit-content;
    padding-left: 20%;
    padding-right: 20%;
    background-color: rgba(68, 76, 108, 0.15);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 2px;
  }

  &__text {
    @include nav;
  }

  &__contact {
    display: flex;
    justify-content: center;

    &-button {
      @include nav;
      width: 140px;
      height: 42px;
      background-color: transparent;
      color: white;
      border-radius: 0;
      text-align: center;
      border: 1px solid white;
      align-self: center;
      margin-top: -3px;
      transition: 0.3s;

      &:hover {
        background-color: white;
        color: #342593;
      }
    }
  }
}
