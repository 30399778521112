@import "../../styles/fonts";
@import "../../styles/padding";

.sectionHeader {
  @include padding-side;

  width: 70%;

  @include desktop {
    width: 22.5%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
  }

  &__text {
    @include home-section-header;
  }

  &__horizontalFrame {
    align-self: flex-end;
    height: 4px;
    width: 65%;
    background-color: #72e1d1;
    margin-right: 10%;
    margin-top: -8px;
    margin-bottom: 8px;

    @include desktop {
      margin-top: -4px;
      margin-right: 10%;
    }
  }
}
