@import "./fonts";

h1 {
  @include page-header;
  margin: 0;
}

h3 {
  @include section-header;
  margin: 0;
}

h4 {
  @include bold;
  margin: 0;
}

h5 {
  @include body-bold;
  margin: 0;
}

p {
  @include body;
  color: white;
}
