@import "../styles/breakpoints";
@import "../styles/fonts";
@import "../styles/padding";

section {
  @include padding-before-headline;
  min-height: fit-content;
  width: 100%;

  @include desktop {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}
.homepage {
  background-color: #131314;
  width: 100%;
  max-width: 2000px;
  margin: auto;
  z-index: 5;

  position: relative;

  &__intro {
    @include padding-component-top-bottom;
    @include padding-page-top;
    @include padding-side;

    @include desktop {
      width: 57%;
      min-height: 40vh;
      height: fit-content;
      padding-bottom: 3%;
    }

    &-heading {
      @include desktop {
        @include bold;
      }
    }
  }

  &__resultsContainer {
    @include padding-side;
    @include padding-component-top-bottom;

    padding-top: 40px;

    background-color: #0e0e10;

    @include desktop {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;

      width: 100%;

      min-height: 45vh;
      height: fit-content;

      padding-left: 0;
      padding-right: 0;
    }
  }

  &__result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include desktop {
      justify-content: center;

      height: 100%;
      width: 25%;

      border: solid 2px;
      border-radius: 5px;
      padding: 4%;
    }
    &-divider {
      height: 4px;
      width: 72px;
      border-radius: 15px;

      @include desktop {
        align-self: flex-start;
        height: 1px;
      }
    }

    &-icon {
      height: 50px;
      width: 50px;

      margin-bottom: 16px;

      @include desktop {
        align-self: flex-start;
        margin-left: 12px;
        margin-bottom: 16px;
      }
    }

    &-text {
      text-align: center;
    }
  }

  &__result1 {
    @include desktop {
      border-color: #7a70b7;
    }

    &-divider {
      background-color: #7a70b7;
    }
  }

  &__result2 {
    @include desktop {
      border-color: #72e1d1;
    }

    &-divider {
      background-color: #72e1d1;
    }
  }

  &__result3 {
    @include desktop {
      border: solid 2px;
      border-color: #125e8a;
    }

    &-divider {
      background-color: #125e8a;
    }
  }

  &__pointsContainer {
    @include padding-side;
    @include padding-component-top-bottom;

    display: flex;
    flex-direction: column;

    position: relative;
  }

  &__point {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    @include desktop {
    }

    &-icon {
      width: 40px;
      height: 40px;

      @include desktop {
        height: 30px;
        width: 30px;
      }
    }

    &-text {
    }
  }

  &__testimonial {
    @include padding-side;
    width: 100%;

    background-color: #0e0e10;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 3%;

    position: relative;
    z-index: 5;

    @include desktop {
      margin-top: 0;
    }

    &-logos {
      width: 100%;

      @include desktop {
        display: flex;
        width: 60%;
        padding: 2%;
        padding-top: 4vh;
        padding-bottom: 4vh;
      }
    }
  }

  &__quote {
    display: none;

    @include desktop {
      display: flex;
      flex-direction: column;

      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 10;

      width: 45%;
    }

    &-smallBubble {
      display: none;

      @include desktop {
        display: flex;
        align-self: flex-end;
        margin-right: 30%;

        position: absolute;
        top: 0;
        transform: translate(0, -50);

        width: 10%;
      }
    }

    &-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      position: relative;
      z-index: 10;
    }

    &-largeBubble {
      width: 100%;
      @include desktop {
        width: 85%;
      }

      @media only screen and (min-width: 1440px) {
        width: 80%;
      }
    }

    &-text {
      position: absolute;
      text-align: center;

      width: 70%;

      @include desktop {
        width: 60%;

        font-size: 150%;
        line-height: 200%;
      }

      @media only screen and (min-width: 1280px) {
        font-size: 22px;
        line-height: 36px;
      }

      @media only screen and (min-width: 1474px) {
        font-size: 22px;
        line-height: 36px;
        width: 56%;
      }

      @media only screen and (min-width: 1579px) {
        font-size: 22px;
        line-height: 40px;
        width: 52%;
      }
    }
  }

  &__contact {
    @include padding-side;
    @include padding-page-end;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5%;

    @include desktop {
      flex-direction: row;
      justify-content: space-between;
      align-self: center;
      // padding-bottom: 0vh;
      // padding-bottom: 0px;
      // padding-top: 10vh;
    }

    &-left {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-around;

      @include desktop {
        width: 40%;
      }
    }

    &-right {
      width: 100%;

      @include desktop {
        width: 60%;
      }
    }
  }

  &__text {
    margin: 0;
  }

  &__email {
    display: flex;
    flex-direction: column;
    //align-self: center;

    &-title {
      margin: 0;
    }

    &-container {
      margin-top: 0;
    }
  }
  &__mailto {
    &-text {
      padding: 0;
      margin: 0;
      text-decoration: underline;
      &:hover {
        color: #72e1d1;
      }
    }
  }
}

.scroll {
  @include desktop {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    scroll-snap-type: y mandatory;
  }
}

.slide {
  @include desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    scroll-snap-align: center;
    flex-direction: column;
  }
}

.slide1 {
  @include desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    scroll-snap-align: center;
    flex-direction: column;
  }
}

.slide2 {
  @include desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    scroll-snap-align: end;
    flex-direction: column;
  }
}

.slide3 {
  @include desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    scroll-snap-align: end;
    flex-direction: column;
  }
}

.slide4 {
  @include desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    scroll-snap-align: end;
    flex-direction: column;
  }
}
