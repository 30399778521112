@import "../../styles/breakpoints";
@import "../../styles/fonts";

.form {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-self: center;
  align-content: center;

  @include desktop {
    width: 100%;
  }

  &__input {
    width: 95%;
    height: 42px;
    border-radius: 0;
    align-self: center;
    border: none;
    box-shadow: none;

    @include desktop {
      width: 100%;
    }
  }

  &__message {
    width: 95%;
    height: 240px;
    border-radius: 0;
    align-self: center;
    padding-top: 10px;
    border: none;
    box-shadow: none;

    @include desktop {
      width: 100%;
      height: 300px;
    }
  }

  &__button {
    @include button;
    width: 120px;
    height: 52px;
    align-self: flex-end;
    margin-right: 2.5%;
    outline: solid 1px #823cac;
    background: none;
    border: none;
    box-shadow: none;
    transition: 0.3s;

    &:hover {
      background-color: #823cac;
    }

    &:active {
      outline: solid 1px #72e1d1;
    }

    @include desktop {
      margin-right: 5px;
    }
  }
}
